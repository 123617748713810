import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Svg,
  Path
} from "@react-pdf/renderer";
import pdfHeader from "../../assets/img/pdfHeader.png";

const generateStyles = (minimize = false) => {
  let cellSize = 60;
  let packageCellSize = 60;
  let cellPackageHeaderStyle = {};
  let headerHeight = 40;

  if (minimize) {
    cellSize = 40;
    packageCellSize = 20;
    cellPackageHeaderStyle = {
      position: "absolute",
      width: "120px",
      transform: "rotate(65deg)",
      transformOrigin: "left",
      textAlign: "left"
    };
    headerHeight = 90;
  }

  return StyleSheet.create({
    page: {
      // display: "flex",
      // flexDirection: "column",
      // backgroundColor: "white",
      // height: "300px",
      // flexWrap: "wrap"
      fontSize: 16,
      fontWeight: 300,
      paddingHorizontal: 10
    },
    headerText: {
      marginHorizontal: 30,
      marginVertical: 30,
      receiverCell: 20,
      textAlign: "center",
      fontSize: 30,
      fontWeight: 700
    },
    tripInfo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around"
    },
    tripInfoItem: {
      display: "flex",
      flexDirection: "row",
      width: 120,
      alignItems: "center"
    },
    icon: { width: 24, height: 24 },
    table: { fontSize: 10, marginTop: 10 },
    units: {
      display: "block",
      width: cellSize * 2,
      borderRight: "1px solid black"
    },
    tableHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderBottom: "1px solid black",
      height: headerHeight
    },
    receiverCell: { width: 150, marginVertical: 5 },
    cell: {
      width: cellSize,
      paddingVertical: 5,
      textAlign: "center",
      display: "block",
      position: "relative"
    },
    packageCell: {
      width: packageCellSize,
      paddingVertical: 5,
      textAlign: "center",
      display: "block",
      position: "relative"
    },
    cellPackageHeader: cellPackageHeaderStyle,
    cellUnit: {
      width: cellSize,
      paddingVertical: 5,
      textAlign: "center",
      display: "block",
      borderRight: "1px solid black"
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    unit: {
      display: "flex",
      flexDirection: "row",
      textAlign: "center",
      flexWrap: "nowrap",
      width: cellSize * 2
    },
    totalRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderTop: "1px solid black",
      borderBottom: "1px solid black"
    },
    endBorder: {
      marginTop: 1,
      width: "100%",
      borderTop: "1px solid black"
    },
    footer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: 12,
      bottom: 10,
      left: 10,
      position: "absolute",
      width: "100%"
    }
  });
};

// Create Document Component
export const TripPdf = ({ t, trip }) => {
  const pageSize = 20;

  const allReceivers = trip.Lines.reduce(
    (acc, line) => ({
      ...acc,
      [line.AddresseeName]: {
        units: {
          ...acc[line.AddresseeName]?.units,
          [line.Unit]:
            parseInt(acc[line.AddresseeName]?.units[line.Unit] || 0) +
            parseInt(line.LoadedQty || line.Quantity)
        },
        packages: {
          ...acc[line.AddresseeName]?.packages,
          ...Object.keys(line.Packages).reduce(
            (a, p) => ({
              ...a,
              [p]:
                parseInt(acc[line.AddresseeName]?.packages[p] || 0) +
                parseInt(line.Packages[p] || 0)
            }),
            {}
          )
        }
      }
    }),
    {}
  );

  const receiversPaged = Object.entries(allReceivers).reduce(
    (acc, [key, value], index) => {
      const accIndex = Math.floor(index / pageSize);

      if (!acc[accIndex]) {
        acc[accIndex] = {};
      }
      acc[accIndex] = { ...acc[accIndex], [key]: value };
      return acc;
    },
    []
  );

  const total = Object.values(allReceivers).reduce(
    (acc, receiver) => ({
      units: {
        ...acc.units,
        ...Object.entries(receiver.units).reduce(
          (a, [key, value]) => ({
            ...a,
            [key]: parseInt(value || 0) + parseInt(acc.units[key] || 0)
          }),
          {}
        )
      },
      packages: {
        ...acc.packages,
        ...Object.entries(receiver.packages).reduce(
          (a, [key, value]) => ({
            ...a,
            [key]: parseInt(value || 0) + parseInt(acc.packages[key] || 0)
          }),
          {}
        )
      }
    }),
    { units: {}, packages: {} }
  );

  const usedPackages = Object.entries(total.packages)
    .filter(([key, value]) => value)
    .map(([key, value]) => key);

  let minimizeStyle = false;

  if (usedPackages.length > 7) {
    minimizeStyle = true;
  }

  const styles = generateStyles(minimizeStyle);

  return (
    <Document>
      {receiversPaged.map((receivers, index) => (
        <Page size="A4" style={styles.page}>
          <Image src={pdfHeader} style={styles.header} />
          {index === 0 && (
            <>
              <Text style={styles.headerText}>
                {`${t("trip")} ${trip.PartialTripNr}`}
              </Text>

              <View style={styles.tripInfo}>
                <View style={styles.tripInfoItem}>
                  <>
                    <Svg style={styles.icon} viewBox="0 0 24 24">
                      <Path
                        fill="#469B5F"
                        d="M18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5M19.5,9.5L21.46,12H17V9.5M6,18.5A1.5,1.5 0 0,1 4.5,17A1.5,1.5 0 0,1 6,15.5A1.5,1.5 0 0,1 7.5,17A1.5,1.5 0 0,1 6,18.5M20,8H17V4H3C1.89,4 1,4.89 1,6V17H3A3,3 0 0,0 6,20A3,3 0 0,0 9,17H15A3,3 0 0,0 18,20A3,3 0 0,0 21,17H23V12L20,8Z"
                      />
                    </Svg>
                    <Text>{trip.Vehicle}</Text>
                  </>
                </View>
                <View style={styles.tripInfoItem}>
                  <>
                    <Svg style={styles.icon} viewBox="0 0 24 24">
                      <Path
                        fill="#469B5F"
                        d="M22,15V17H10A3,3 0 0,1 7,20A3,3 0 0,1 4,17H2V6A2,2 0 0,1 4,4H17A2,2 0 0,1 19,6V15H22M7,16A1,1 0 0,0 6,17A1,1 0 0,0 7,18A1,1 0 0,0 8,17A1,1 0 0,0 7,16Z"
                      />
                    </Svg>
                    <Text>{trip.Trailer}</Text>
                  </>
                </View>
              </View>
            </>
          )}

          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={styles.receiverCell}>{t("adreseeName")}</Text>
              <View style={styles.unit}>
                <Text style={styles.cell}>{t("unit")}</Text>
                <Text style={styles.cellUnit}>{t("qty")}</Text>
              </View>
              {usedPackages.map(p => (
                <View style={styles.packageCell}>
                  <Text style={styles.cellPackageHeader}>{t(p)}</Text>
                </View>
              ))}
            </View>
            <View style={styles.tableBody}>
              {Object.entries(receivers).map(([receiverName, receiver]) => (
                <View style={styles.tableRow}>
                  <Text style={styles.receiverCell}>{receiverName}</Text>
                  <View style={styles.units}>
                    {Object.entries(receiver.units).map(([key, value]) => (
                      <View style={styles.unit}>
                        <Text style={styles.cell}>{key}</Text>
                        <Text style={styles.cell}>{value}</Text>
                      </View>
                    ))}
                  </View>
                  {usedPackages.map(p => (
                    <Text style={styles.packageCell}>
                      {receiver.packages[p] || ""}
                    </Text>
                  ))}
                </View>
              ))}
            </View>
            {index + 1 === receiversPaged.length && (
              <>
                <View style={styles.totalRow}>
                  <Text style={styles.receiverCell}>{t("total")}</Text>
                  <View style={styles.units}>
                    {Object.entries(total.units).map(([key, value]) => (
                      <View style={styles.unit}>
                        <Text style={styles.cell}>{key}</Text>
                        <Text style={styles.cell}>{value}</Text>
                      </View>
                    ))}
                  </View>
                  {usedPackages.map(p => (
                    <Text style={styles.packageCell}>
                      {total.packages[p] || ""}
                    </Text>
                  ))}
                </View>
                <View style={styles.endBorder}></View>
              </>
            )}
          </View>
          <View style={styles.footer}>
            <Text>{`${t("page")} ${index + 1}`}</Text>
            <Text>{localStorage.getItem("userDepartmentName")}</Text>
          </View>
        </Page>
      ))}
    </Document>
  );
};
