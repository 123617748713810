import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Icon,
  Menu,
  MenuItem,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import { isParkingApp } from "../utils/domain";

import { useAuth } from "../context/auth";
import { useLang } from "../context/lang";
import { useAccessibility } from "../context/accessibility";

import logo from "../assets/img/logo.png";
import { COLORS } from "../config/colors";

const Header = ({ location }) => {
  const { isAuthenticated, logout } = useAuth();
  const styles = useStyles();
  const { t, getAvailibleLangs, setLang } = useLang();
  const { topTabIndex } = useAccessibility();
  const [menuEl, setMenuEl] = React.useState(null);

  const openLangMenu = event => {
    setMenuEl(event.currentTarget);
  };

  const closeLangMenu = () => {
    setMenuEl(null);
  };

  const chooseLanguage = lang => {
    setLang(lang);
    closeLangMenu();
  };

  return (
    <AppBar position="absolute" color="transparent" className={styles.appBar}>
      <Toolbar className={styles.toolbar}>
        {isAuthenticated ? (
          <>
            {" "}
            <Grid container className={styles.smallColumn}>
              <Grid
                item
                sm={8}
                xs={12}
                className={`${styles.smallCenter} ${styles.smallColumn}`}
                container
                alignItems="center"
              >
                <img className={styles.logo} src={logo} alt="Logo" />
                <Typography
                  className={`${styles.feedback} ${styles.textLeft}`}
                  variant="h6"
                >
                  {t("feedback")}
                </Typography>
                {/* <Typography
                  className={`${styles.textLeft}`}
                  variant="h6"
                  color="error"
                >
                  THIS IS PRODUCTION DATA
                </Typography> */}
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
                container
                justify="flex-end"
                alignItems="center"
                className={`${styles.smallCenter} ${styles.smallColumn}`}
              >
                <Grid item>
                  <Button onClick={logout} tabIndex={topTabIndex}>
                    {t("logout")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    aria-controls="lang-menu"
                    aria-haspopup="true"
                    onClick={openLangMenu}
                    size="small"
                    className={styles.margin}
                    variant="outlined"
                    tabIndex={topTabIndex}
                  >
                    <img className={styles.langLogo} src={t("flagUrl")} />
                    {t("languageName")}
                    {Boolean(menuEl) ? (
                      <Icon>expand_less</Icon>
                    ) : (
                      <Icon>expand_more</Icon>
                    )}
                  </Button>
                  <Menu
                    id="lang-menu"
                    anchorEl={menuEl}
                    open={Boolean(menuEl)}
                    onClose={closeLangMenu}
                  >
                    {getAvailibleLangs().map((lang, key) => (
                      <MenuItem
                        key={key}
                        onClick={() => chooseLanguage(lang.code)}
                      >
                        <img className={styles.langLogo} src={lang.flagUrl} />
                        {lang.title}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <img className={styles.logo} src={logo} alt="Logo" />
            <Typography className={styles.feedback} variant="h6">
              {isParkingApp() ? t("parkingApp") : t("feedback")}
            </Typography>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

const HeaderRouter = withRouter(Header);

export { HeaderRouter as Header };

const useStyles = makeStyles(theme => ({
  appBar: {
    borderBottom: `1px solid ${COLORS.GREEN}`,
    marginBottom: 5,
    boxShadow: "0 0 0 0",
    padding: "0",
    "& .MuiToolbar-root": {
      padding: 0
    }
  },
  logo: {
    width: "9rem",
    paddingLeft: ".5rem"
  },
  toolbar: {
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  },
  langLogo: {
    width: "1.5rem",
    margin: "0.2rem"
  },
  feedback: {
    flexGrow: 1,
    textAlign: "right",
    marginLeft: "1rem",
    color: COLORS.DARK_GREEN,
    [theme.breakpoints.up("md")]: {
      marginLeft: "4rem"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      flexGrow: 0
    },
    marginRight: ".5rem"
  },
  textLeft: {
    textAlign: "left"
  },
  margin: {
    margin: ".6rem"
  },
  smallCenter: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center"
    }
  },
  smallColumn: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  }
}));
