import React from "react";

const AccessibilityContext = React.createContext();

/** Used for controlling accessability flows
 * Trip tables are firstly accessible, than after first focus dashboard settings panel is accessible
 */
function AccessibilityProvider(props) {
  const [isTopAccessible, setIsTopAccessible] = React.useState(false);

  const topTabIndex = isTopAccessible ? "0" : "-1";

  const lockTopAccessibility = () => setIsTopAccessible(false);

  const unlockTopAccessibility = () => setIsTopAccessible(true);

  return (
    <AccessibilityContext.Provider
      value={{
        lockTopAccessibility,
        unlockTopAccessibility,
        topTabIndex
      }}
      {...props}
    />
  );
}

const useAccessibility = () => React.useContext(AccessibilityContext);

export { useAccessibility, AccessibilityProvider };
