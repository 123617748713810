export function highlightText(
  searchTerm: string,
  el = document.getElementsByTagName("body")?.[0] as HTMLElement | null
) {
  if (el) {
    removeHighlights(el);
    // Get all elements containing text
    const elements = el.querySelectorAll(".search-highlightable");
    // Loop through each element
    elements.forEach(element => {
      const text = element.innerHTML.latinise();
      const regex = new RegExp(
        `(${searchTerm.toLowerCase().latinise()})`,
        "gi"
      );
      // Check if the element's text contains the search term
      if (text && text.toLowerCase().match(regex)) {
        // Split the text into parts (matched and unmatched)
        const parts = text.split(regex);
        // Create a new HTML structure with the matched term highlighted
        const highlightedText = parts
          .map((part: any) =>
            part.match(regex)
              ? `<span class="search-highlights" style="background-color: yellow;">${part}</span>`
              : part
          )
          .join("");
        // Replace the original text with the highlighted version
        element.innerHTML = highlightedText;
      }
    });
  }
}

export function removeHighlights(
  el = document.getElementsByTagName("body")?.[0] as HTMLElement | null
) {
  el?.querySelectorAll(".search-highlights").forEach(
    highlight => (highlight.outerHTML = highlight.innerHTML)
  );
}
