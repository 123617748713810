import React from "react";
import createPersistedState from "use-persisted-state";
import _ from "lodash";

import { checkShipmentsAlerts } from "../api";
import {
  IPlan,
  ITrip,
  ShipmentAlert
} from "navision-proxy-api/@types/terminal";
import { INAVShipmentLog } from "navision-proxy-api/@types/navTypes";

import { useSnackbar } from "./snackbar";
import moment from "moment";
import { useLang } from "./lang";
import { DEFAULT_DATE_FILTER } from "../config/app";

export const getShipmentKey = (shipmentLog?: INAVShipmentLog) => {
  return shipmentLog
    ? `${shipmentLog.Record_ID.split(",")?.[3]}-${
        shipmentLog.Record_ID.split(",")?.[4]
      }`
    : "";
};

export const getShipmentTripNr = (shipmentLog?: INAVShipmentLog) => {
  return shipmentLog
    ? shipmentLog.Primary_Key_Field_1_Value +
        "-" +
        shipmentLog.Primary_Key_Field_2_Value[0]
    : "";
};

interface IStoreContext {
  editedTrips: string[];
  initEditedTripsFromPlans: (shipmentsData: IPlan[]) => string[];
  pushToEditedTrips: (trip: ITrip) => void;
  resetEditedTrips: () => void;
  removeFromEditedTrips: (trip: ITrip) => void;
  alertedShipments: React.MutableRefObject<ShipmentAlert[]>;
  checkTripsInserts: (dateFilter: string, showAlert?: boolean) => Promise<void>;
}

const StoreContext = React.createContext<IStoreContext>({} as IStoreContext);
const useEditedTripsState = createPersistedState<string[]>("editedTrips");

const TRIP_FIRST_CHECK_INTERVAL = 1000 * 5;

const TRIP_CHECKS_INTERVAL = 1000 * 60;

/** Global app settings */
function StoreProvider(props: any) {
  const { openSnackbar } = useSnackbar();
  const [editedTrips, setEditedTrips] = useEditedTripsState([]); //for moving edited trips to bottom in feedback
  const { t } = useLang();

  /** we store it as a ref because we don't want to rerender when new alerts incoming */
  const alertedShipments = React.useRef<ShipmentAlert[]>([]);

  /** we are checking for the shipments that was replanned after was inserted */
  const tripInsertsCheckTimeout = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    // if (!localStorage.getItem("lastTripsCheckDateTime")) {
    //   localStorage.setItem("lastTripsCheckDateTime", new Date().toISOString());
    // }
    //initTripsInserts();
    return () => {
      if (tripInsertsCheckTimeout.current)
        clearTimeout(tripInsertsCheckTimeout.current);
    };
  }, []);

  // const initTripsInserts = async () => {
  //   //if we start in a show alert mode we need to first check the alerts
  //   if (localStorage.getItem("showAlertMode") == "true") {
  //     setLoadingInitAlerts(true);
  //   }

  //   await checkTripsInserts();
  //   setLoadingInitAlerts(false);
  // };

  const scheduleCheckingTripsInserts = (timeout = TRIP_CHECKS_INTERVAL) => {
    if (tripInsertsCheckTimeout.current)
      clearTimeout(tripInsertsCheckTimeout.current);
    tripInsertsCheckTimeout.current = setTimeout(() => {
      console.debug("checking trips inserts from timeout");

      //figure out the filter date in a hacky way
      let pickerFilter = moment(DEFAULT_DATE_FILTER).format("YYYY-MM-DD");
      const pickerDateValues = (
        document.getElementById("date-picker-inline") as any
      )?.value?.split("/");
      if (pickerDateValues) {
        pickerFilter = `${pickerDateValues[2]}-${pickerDateValues[1]}-${pickerDateValues[0]}`;
      }

      checkTripsInserts(pickerFilter);
    }, timeout);
  };

  const checkTripsInserts = async (dateFilter: string) => {
    try {
      const lastTripsCheckDateTime = localStorage.getItem(
        "lastTripsCheckDateTime"
      );

      let timeFrom =
        lastTripsCheckDateTime || moment().startOf("day").toISOString();
      // //last trip check time works only for today
      // //for the others day it will check just for the whole day
      // //ideally we should check the current filter date
      // if (
      //   lastTripsCheckDateTime &&
      //   moment(lastTripsCheckDateTime).isSame(new Date(), "day")
      // ) {
      //   timeFrom = lastTripsCheckDateTime;
      //   console.log("time from updated");
      // }

      //schedule next right away
      scheduleCheckingTripsInserts();

      console.log("checking trips inserts", dateFilter, timeFrom);
      const alerts: ShipmentAlert[] = await checkShipmentsAlerts(
        dateFilter,
        timeFrom
      );

      localStorage.setItem("lastTripsCheckDateTime", new Date().toISOString());
      console.log("new alerts", alerts);

      if (alerts.length > 0) {
        const oldAlerts = alertedShipments.current;
        console.log("oldAlerts", oldAlerts);
        alertedShipments.current = alerts;

        if (
          alerts.filter(a => !a.alertApproved).length > oldAlerts.length || //length is different
          alerts.some((a, i) => oldAlerts[i]?.log?.Entry_No != a?.log?.Entry_No) //some of the alerts are different
        ) {
          //new alerts incoming
          const showAlertMode = localStorage.getItem("showAlertMode") == "true";

          if (!showAlertMode) {
            openSnackbar(
              t("shipmentsMovedAlert"),
              // ${insertedShipments
              //   .map((shipment: any) => {
              //     const recordId =
              //       shipment.Record_ID.split(": ")?.[1].split(",");
              //     return `Shipment Nr: ${recordId[3]}, New Trip Nr: ${recordId[0]}`;
              //   })
              //   .join(
              //     "\n"
              //   )} \n\nPlease, click HARD REFRESH to see the updated trips.`,
              "warning",
              {
                title: t("showNotifications"),
                action: () =>
                  document.getElementById("showNotificationsButton")?.click()
              }
            );
          }

          if (showAlertMode) {
            document.getElementById("refresh-button")?.click(); //refresh trips list
          }
        }
      }
    } catch (err: any) {
      openSnackbar(
        "Error checking inserted shipments: " + err.message,
        "error"
      );
      if (tripInsertsCheckTimeout.current)
        clearTimeout(tripInsertsCheckTimeout.current);
    }
  };

  const initEditedTripsFromPlans: IStoreContext["initEditedTripsFromPlans"] =
    plans => {
      //we need to store edited trips in the local storage because we want to indicate them before this info appears in the backend and the whole thing reloads
      const newEditedTrips: string[] = [];
      plans.forEach(({ Trips }) =>
        Trips.forEach(trip => {
          if (
            trip.Lines.every(({ touchedDate }) => {
              return Boolean(touchedDate);
            })
          ) {
            newEditedTrips.push(trip.PartialTripNr);
          }
        })
      );
      try {
        //HOT fix for persistent state windws bug - TypeError: Cannot read property 'emit' of null persistent state

        if (!_.isEqual(newEditedTrips, editedTrips))
          setEditedTrips(newEditedTrips);
      } catch (err) {}

      return newEditedTrips;
    };
  const pushToEditedTrips: IStoreContext["pushToEditedTrips"] = trip => {
    setEditedTrips([...editedTrips, trip.PartialTripNr]);
  };

  const resetEditedTrips = () => {
    setEditedTrips([]);
  };

  const removeFromEditedTrips: IStoreContext["removeFromEditedTrips"] =
    trip => {
      setEditedTrips(prev => prev.filter(t => t !== trip.PartialTripNr));
    };

  return (
    <StoreContext.Provider
      value={{
        editedTrips,
        initEditedTripsFromPlans,
        pushToEditedTrips,
        resetEditedTrips,
        removeFromEditedTrips,
        //should not use it from the store because it is not gettings updated
        //should use it directly from the local storage
        alertedShipments,
        checkTripsInserts
        // setAlertedShipments
      }}
      {...props}
    />
  );
}

const useStore = () => React.useContext(StoreContext);

export { useStore, StoreProvider };
