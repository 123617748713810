import React from "react";
import { withRouter } from "react-router-dom";

import { login as loginApi, logout as logoutApi } from "../api";

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [authState, setAuthState] = React.useState(
    checkAuth() ? "done" : "init"
  );

  const isAuthenticated = authState === "done";
  const isLoading = authState === "loading";
  const isError = authState === "error";

  const [errorMessage, setErrorMessage] = React.useState("");

  const [visiblePackages, setVisiblePackages] = React.useState(
    (localStorage.getItem("visiblePackages") || "").split(",")
  );

  const [userId, setUserId] = React.useState(
    localStorage.getItem("userId") || null
  );

  const [userDepartment, setUserDepartment] = React.useState(
    localStorage.getItem("userDepartment") || null
  );

  const [userLanguage, setUserLanguage] = React.useState(
    localStorage.getItem("userLanguage") || "en"
  );

  const [userDepartmentName, setUserDepartmentName] = React.useState(
    localStorage.getItem("userDepartmentName") || null
  );

  const login = async (username, password) => {
    //New state userDepartmentName That is getting data from the localstorage

    try {
      setAuthState("loading");
      await loginApi(username, password);
      setVisiblePackages(localStorage.getItem("visiblePackages").split(","));
      setUserId(localStorage.getItem("userId"));
      setUserLanguage(localStorage.getItem("userLanguage"));
      setUserDepartment(localStorage.getItem("userDepartment"));
      setUserDepartmentName(localStorage.getItem("userDepartmentName"));
      setAuthState("done");
      props.history.push("/");
      return true;
    } catch (error) {
      console.log("error");
      console.log(error);
      setAuthState("error");
      setErrorMessage(error.message);
      return false;
    }
  }; // make a login request
  const logout = async () => {
    await logoutApi();
    localStorage.clear();
    setAuthState("init");
  };

  //Add the userDepartmentName to this object
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        isError,
        errorMessage,
        login,
        logout,
        visiblePackages,
        userId,
        userLanguage,
        userDepartment,
        userDepartmentName
      }}
      {...props}
    />
  );
}

const checkAuth = () => !!localStorage.getItem("userId");

const useAuth = () => React.useContext(AuthContext);

const AuthProviderRouter = withRouter(AuthProvider);

export { useAuth, AuthProviderRouter as AuthProvider };
