import React from "react";
import { TRANSLATION } from "../config/languages";
import moment from "moment";

const LangContext = React.createContext();

function LangProvider(props) {
  const storLang = localStorage.getItem("userLanguage");
  const [lang, setLanguage] = React.useState(
    storLang && storLang !== "null" ? storLang : "en"
  );

  const setLang = lang => {
    setLanguage(lang);
    localStorage.setItem("userLanguage", lang);
  };

  moment.locale(lang);

  const t = key =>
    TRANSLATION[lang]?.[key] || TRANSLATION[lang]?.[key?.toLowerCase()] || key;

  const getAvailibleLangs = () =>
    Object.keys(TRANSLATION).map(langCode => ({
      code: langCode,
      title: TRANSLATION[langCode].languageName,
      flagUrl: TRANSLATION[langCode].flagUrl
    }));

  return (
    <LangContext.Provider
      value={{
        getAvailibleLangs,
        lang,
        setLang,
        t
      }}
      {...props}
    />
  );
}

const useLang = () => React.useContext(LangContext);

export { useLang, LangProvider };
