import React from "react";
import createPersistedState from "use-persisted-state";
import _ from "lodash";

import { fetchDrivers } from "../api";

const DriversStoreContext = React.createContext();

/** Global app settings */
function DriversStoreProvider(props) {
  /**
   * @deprecated - legacy for parking app which should be moved to another client
   */
  const [driversLookup, setDriversLookup] = React.useState({}); //for parking app

  const fetchDriversLookup = async () => {
    const drivers = await fetchDrivers();
    setDriversLookup(
      drivers.reduce(
        (acc, { number, fullName }) => ({
          ...acc,
          [number]: fullName
        }),
        {}
      )
    );
  };

  return (
    <DriversStoreContext.Provider
      value={{
        driversLookup,
        fetchDriversLookup
      }}
      {...props}
    />
  );
}

const useDriversStore = () => React.useContext(DriversStoreContext);

export { useDriversStore, DriversStoreProvider };
