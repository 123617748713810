import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

export const Loader = ({
  loading,
  size = 20,
  color = "primary",
  margin = 0,
  marginX = "auto",
  children
}: any) =>
  loading ? (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      m={margin}
      mx={marginX}
    >
      <CircularProgress color={color} size={size} />
    </Box>
  ) : (
    children
  );
