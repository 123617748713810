import React from "react";
import Icon from "@material-ui/core/Icon";
import { Loader } from "./Loader";
import { getShipmentPdf } from "../api";
import PDFMerger from "pdf-merger-js/browser";
import { Button, Tooltip } from "@material-ui/core";
import { useLang } from "../context/lang";

export default ({ shipmentId }) => {
  const [loading, setLoading] = React.useState(false);
  const { t } = useLang();

  const handleDownload = async () => {
    setLoading(true);
    const pdfBlob = await getShipmentPdf(shipmentId);

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = "data:application/octet-stream;base64," + pdfBlob;
    a.download = `booking_${shipmentId}.pdf`;
    a.click();
    setLoading(false);
  };

  return (
    <Loader loading={loading}>
      <Tooltip title={t("downloadPdf")}>
        <Icon onClick={handleDownload}>picture_as_pdf</Icon>
      </Tooltip>
    </Loader>
  );
};

export const NavPdfDownloaderMany = ({ shipmentIds }) => {
  const [loading, setLoading] = React.useState(false);
  const { t } = useLang();

  const handleDownload = async () => {
    setLoading(true);
    const pdfBlobs = await Promise.all(
      shipmentIds.map(id => getShipmentPdf(id))
    );

    const merger = new PDFMerger();

    for (const pdfBlob of pdfBlobs) {
      await merger.add("data:application/octet-stream;base64," + pdfBlob);
    }

    const mergedPdfBlob = await merger.saveAsBlob();

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = URL.createObjectURL(mergedPdfBlob);
    a.download = `bookings_freightletter.pdf`;
    a.click();
    setLoading(false);
  };

  return (
    <Loader loading={loading}>
      <Button onClick={handleDownload} color="primary" variant="outlined">
        {t("downloadPdf")}
      </Button>
    </Loader>
  );
};
