import React from "react";
import qs from "qs";
import TripTablePanel from "../components/TripTablePanel";
import { fetchPlans, fetchDepartmentConfig } from "../api";
import { Grid, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import { useStore } from "../context/store";
import { useLang } from "../context/lang";

export const TvScreenView = () => {
  const [trips, setTrips] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const styles = useStyles();
  const location = useLocation();
  const { editedTrips, initEditedTripsFromPlans } = useStore();
  const { t } = useLang();

  React.useEffect(() => {
    loadTrips();

    // Autoscroll with smoothscroll every 20 secounds. When reaching the bottom of the window, it goes to another department, reloads every time it changes department
    smoothscroll.polyfill();
    var scroll = setInterval(scrollTimer, 20000);

    function scrollTimer() {
      var height = window.innerHeight - 200;

      window.scrollBy({ top: height, left: 0, behavior: "smooth" });
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        window.scrollTo({ top: 0 });
      }
    }
  }, []);

  const loadTrips = async () => {
    setLoading(true);
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    let departmentQuery;
    if (query.department) {
      departmentQuery = await fetchDepartmentConfig(query.department);
    }
    const { Plans } = await fetchPlans(
      departmentQuery || query //fetching could use query params
    );
    if (Plans.length === 0) {
      setError(`No trips availible for user :(`);
    } else {
      initEditedTripsFromPlans(Plans);
      setTrips(Plans.reduce((acc, { Trips }) => [...acc, ...Trips], []));
    }
    setLoading(false);
  };

  // const tripPanels = trips.map((trip, key) => {
  //   return (
  //     <Grid
  //       key={`${trip.PartialTripNr}_${key}`}
  //       className={styles.marginTop}
  //       item
  //       xs={12}
  //     >
  //       <TripTablePanel
  //         editable={false}
  //         trip={trip}
  //         disabled={true}
  //         showReadyTime={true}
  //         disableUnits={false}
  //         />
  //     </Grid>
  //   );
  // });

  //Sorted by StartTime and then Vehicle

  const tripSort = trips
    .sort(function (a, b) {
      var timeA = a.StartTime;
      var timeB = b.StartTime;

      var vehicleA = a.Vehicle;
      var vehicleB = b.Vehicle;

      if (timeA < timeB) {
        return -1;
      }
      if (timeA > timeB) {
        return 1;
      }
      if (vehicleA < vehicleB) {
        return -1;
      }
      if (vehicleA > vehicleB) {
        return 1;
      }
      return 0;
    })
    .sort((a, b) => {
      if (
        editedTrips.includes(a.PartialTripNr) &&
        !editedTrips.includes(b.PartialTripNr)
      ) {
        return -1;
      } else if (
        !editedTrips.includes(a.PartialTripNr) &&
        editedTrips.includes(b.PartialTripNr)
      ) {
        return 1;
      } else {
        return 0;
      }
    });

  const sortedTrips = tripSort.map((trip, key) => {
    return (
      <Grid
        key={`${trip.PartialTripNr}_${key}`}
        className={styles.marginTop}
        item
        xs={12}
      >
        <TripTablePanel editable={false} trip={trip} disabled tvMode />
      </Grid>
    );
  });

  return (
    <Grid container>
      {loading ? (
        <Grid container justify="center">
          <CircularProgress size="10rem" />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} container wrap="wrap" className={styles.headline}>
            <Grid md={2} xs={6} item container alignContent="center">
              <Typography
                style={{ fontWeight: "bold", marginLeft: "1em" }}
                variant="h5"
              >
                {t("partialTripNr")}
              </Typography>
            </Grid>
            <Grid md={2} xs={6} item container alignContent="center">
              <Typography
                style={{ fontWeight: "bold", marginLeft: ".5em" }}
                variant="h5"
              >
                {t("units")}
              </Typography>
            </Grid>
            <Grid md={2} xs={6} item container alignContent="center">
              <Typography style={{ fontWeight: "bold" }} variant="h5">
                {t("confirmed")}
              </Typography>
            </Grid>
            <Grid md={2} xs={6} item container alignContent="center">
              <Typography style={{ fontWeight: "bold" }} variant="h5">
                {t("trailer")}
              </Typography>
            </Grid>
            <Grid md={1} xs={6} item container alignContent="center">
              <Typography style={{ fontWeight: "bold" }} variant="h5">
                {t("vehicle")}
              </Typography>
            </Grid>
            <Grid md={1} xs={6} item container alignContent="center">
              <Typography style={{ fontWeight: "bold" }} variant="h5">
                {t("actualGate")}
              </Typography>
            </Grid>
            <Grid md={1} xs={6} item container alignContent="center">
              <Typography style={{ fontWeight: "bold" }} variant="h5">
                {t("startTime")}
              </Typography>
            </Grid>
            <Grid md={1} xs={6} item container alignContent="center">
              <Typography style={{ fontWeight: "bold" }} variant="h5">
                {t("updated")}
              </Typography>
            </Grid>
          </Grid>
          <div style={{ height: "90px", width: "100px" }}></div>
          {sortedTrips}
        </>
      )}
      {error && (
        <Typography color="error" variant="h4">
          {error}
        </Typography>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  marginTop: {
    marginTop: 5
  },
  rightAlign: {
    textAlign: "right"
  },
  smallReverse: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row-reverse"
    }
  },
  headline: {
    background: "white",
    position: "fixed",
    height: "85px",
    top: 0,
    left: 0,
    zIndex: 9999,
    borderRadius: "0px 0px 4px 4px",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    width: "100%",
    padding: "0px 8px",
    [theme.breakpoints.down("xs")]: {
      height: "125px",
      flexDirection: "row-reverse"
    }
  }
}));
