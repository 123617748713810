import React from "react";
//import "react-virtualized/styles.css";
import InfiniteScroll from "./InfiniteScroll";

export default (props: any) => {
  const initCount = Math.floor((window.innerHeight - 200) / 60);
  return (
    <tbody className="MuiTableBody-root">
      <InfiniteScroll
        initCount={initCount}
        onRender={props.onRender}
        scrollElement={props.tableRef.current?.tableContainerDiv.current} //TODO: find more buletproof way to get to the material table's scrolling container
      >
        {renderedItemsCount =>
          props.renderData
            .slice(0, renderedItemsCount)
            .map((data: any, index: any) =>
              data.tableData.editing ? (
                <props.components.EditRow
                  key={index}
                  index={index}
                  data={data}
                  mode={data.tableData.editing}
                  {...props}
                />
              ) : (
                <props.components.Row
                  key={index}
                  index={index}
                  data={data}
                  {...props}
                />
              )
            )
        }
      </InfiniteScroll>
    </tbody>
  );
};
