import React from "react";
import { MTableEditField } from "material-table";
import _ from "lodash";
import { MenuItem, Select, TextField } from "@material-ui/core";

const EditField = ({
  id,
  isVisible,
  initValue,
  saveField,
  autoFocus,
  rowIndex,
  partialTripNr,
  onFocus,
  isLast,
  ...rest
}: any & {
  id: string;
  isVisible: boolean;
  initValue: string;
  //saves value to a parent ref
  saveField: (e: any, value: string) => void;
  autoFocus: boolean;
  rowIndex: number;
  partialTripNr: string;
  onFocus: (e: any) => void;
  isLast: boolean;
  rest: any;
}) => {
  const lookupedValue =
    Object.keys(rest?.columnDef?.lookup || {}).find(
      key => rest.columnDef.lookup[key] === initValue
    ) || null;

  const _initValue = initValue;

  //state is used only for select input, for others ref is used
  const [selectValue, setSelectValue] = React.useState<null | string>(
    lookupedValue
  );

  //if there is a lookup but no corresponding value for lookup just add it. TODO:upodates lookup to match all possible values
  if (rest.columnDef.lookup && !lookupedValue) {
    rest.columnDef.lookup = {
      ...rest.columnDef.lookup,
      [initValue]: initValue
    };
  }

  const inputRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus({ preventScroll: false });
    }
    //console.log(_initValue);
    // if (rest.columnDef.field == "Unit") {
    //   console.log(" Unit _initValue");
    //   console.log(_initValue);
    //   console.log(inputRef);
    // }

    handleChange(_initValue);
  }, []);

  const handleChange = (value = "") => {
    let newValue = value;
    if (typeof newValue == "number" && isNaN(newValue)) {
      newValue = "0";
    }
    // if (newValue) {
    //   console.log(inputRef.current, inputRef.current.value, newValue);
    // }

    if (inputRef.current) inputRef.current.value = newValue;
  };

  //console.log(rest);

  // return (
  //   <MTableEditField
  //     onChange={() => {}}
  //     size="small"
  //     inputProps={{
  //       onChange: (e: any) => {
  //         saveField(e, e.target.value); //save for
  //         handleChange(e.target.value);
  //       },
  //       ref: inputRef,
  //       // onBlur: e => {
  //       //   saveField(e, value);
  //       // },
  //       onFocus: ({ target }: any) => {
  //         if (target.tagName === "INPUT") {
  //           target.select();
  //           onFocus();
  //         }
  //       },
  //       onKeyDown: (e: any) => {
  //         //ignore floats
  //         if (e.key == "." || e.key == ",") {
  //           e.preventDefault();
  //         }
  //       },
  //       rowIndex,
  //       isLast: isLast ? "true" : "false",
  //       className: `navigatableField${partialTripNr} ${id}`,
  //       id,
  //       tripId: partialTripNr,
  //       editable: rest.columnDef.editable, // for arrow navigating
  //       field: rest.columnDef.field // for arrow navigating
  //     }}
  //     {..._.omit(rest, ["value"])}
  //   />
  // );
  //return <span></span>;
  if (rest.columnDef.lookup) {
    //select

    return (
      <Select
        {...rest}
        value={selectValue}
        // className={`navigatableField${partialTripNr.replaceAll(
        //   " ",
        //   "__"
        // )} ${id}`}
        //renderValue={(v: any) => _initValue}
        InputCla
        inputProps={{
          ...rest.inputProps,
          ref: inputRef,
          rowIndex,
          islast: isLast,
          className: `navigatableField${partialTripNr.replaceAll(
            " ",
            "__"
          )} ${id}`,
          // for arrow navigating
          tripId: partialTripNr,
          editable: rest.columnDef?.editable,
          field: rest.columnDef?.field, // for arrow navigating
          tabIndex: 0
        }}
        //value={this.props.value === undefined ? "" : this.props.value}
        onChange={(e: any) => {
          saveField(e, e.target.value); //save for
          setSelectValue(e.target.value);
        }}
        style={{
          fontSize: 13
        }}
        SelectDisplayProps={{ "aria-label": rest.columnDef.title }}
      >
        {Object.keys(rest.columnDef.lookup).map(key => (
          <MenuItem key={key} value={key}>
            {rest.columnDef.lookup[key]}
          </MenuItem>
        ))}
      </Select>
    );
  }
  return (
    <div
      className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth"
      //scrollwidth="0"
      style={{ float: "right" }}
    >
      <div
        className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl"
        style={{ fontSize: "13px" }}
        onFocus={e => {
          e.target.parentElement?.classList.add("Mui-focused");
        }}
        onBlur={e => {
          e.target.parentElement?.classList.remove("Mui-focused");
        }}
      >
        <input
          id={id}
          type={rest.columnDef.type === "numeric" ? "number" : "text"}
          className={`MuiInputBase-input MuiInput-input navigatableField${partialTripNr.replaceAll(
            " ",
            "__"
          )} ${id}`}
          {...{
            "aria-label": rest.columnDef.title,
            onChange: (e: any) => {
              //console.log("on change", e.target.value);
              saveField(e, e.target.value); //save for
              //no need for handle change here since it uncontrolled
              //handleChange(e.target.value);
            },
            ref: inputRef,
            // onBlur: e => {
            //   saveField(e, value);
            // },
            onFocus: ({ target }) => {
              if (target.tagName === "INPUT") {
                target.select();
                onFocus();
              }
            },
            onKeyDown: e => {
              //ignore floats for packages
              if (
                rest.columnDef.field.includes("Packages.") &&
                (e.key == "." || e.key == ",")
              ) {
                e.preventDefault();
              }
            },
            //editable: "always",
            rowIndex,
            islast: isLast,

            tripId: partialTripNr,
            editable: rest.columnDef?.editable, // for arrow navigating
            field: rest.columnDef?.field, // for arrow navigating
            tabIndex: 0
          }}
        />
      </div>
    </div>
  );
  // return (
  //   <TextField
  //     {...rest}
  //     fullWidth
  //     style={rest.columnDef.type === "numeric" ? { float: "right" } : {}}
  //     type={rest.columnDef.type === "numeric" ? "number" : "text"}
  //     placeholder={rest.columnDef.editPlaceholder || rest.columnDef.title}
  //     //value={this.props.value === undefined ? "" : this.props.value}
  //     onChange={e => {
  //       saveField(e, e.target.value); //save for
  //       handleChange(e.target.value);
  //     }}
  //     InputProps={{
  //       style: {
  //         fontSize: 13
  //       },
  //       inputProps: {
  //         "aria-label": rest.columnDef.title,
  //         onChange: (e: any) => {
  //           saveField(e, e.target.value); //save for
  //           handleChange(e.target.value);
  //         },
  //         ref: inputRef,
  //         // onBlur: e => {
  //         //   saveField(e, value);
  //         // },
  //         onFocus: ({ target }) => {
  //           if (target.tagName === "INPUT") {
  //             target.select();
  //             onFocus();
  //           }
  //         },
  //         onKeyDown: e => {
  //           //ignore floats
  //           if (e.key == "." || e.key == ",") {
  //             e.preventDefault();
  //           }
  //         }
  //       }
  //     }}
  //   />
  // );
};

export default EditField;
