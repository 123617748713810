import gbFlag from "../assets/img/gb_flag.svg";
import dkFlag from "../assets/img/dk_flag.svg";

//download flag that you need from https://hjnilsson.github.io/country-flags/
//save it to assets/img/ and import it here

export const TRANSLATION = {
  en: {
    languageName: "English",
    flagUrl: gbFlag,
    feedbackApp: "Feedback App",
    login: "login",
    feedback: "Feedback",
    username: "Username",
    password: "Password",
    terminal: "Terminal list",
    hardRefresh: "Hard refresh",
    search: "Search tour",
    showHide: "Show/Hide table fields",
    showHideDeps: "Show/Hide subdepartments",
    logout: "Logout",
    profile: "Profile",
    //table fields
    sendername: "Sender",
    clientsClient: "Client client’s nr.",
    adreseeName: "Reciever",
    adresseCountry: "Country",
    qty: "Qty",
    loadedQty: "Actual Qty",
    unit: "Unit",
    units: "Units",
    t5rfidcc: "T5 RFID CC",
    forl: "FORL",
    eupl: "EUPL",
    actualGate: "Actual Gate",
    hylder: "Hylder",
    addRecord: "Add to Daylist",
    addTruck: "Add to morning list",
    deleteRecord: "Delete from the MorningList",
    trailer: "Trailer",
    //new fields
    rfidCc: "RFID CC",
    halfRfidCc: "½RFID CC",
    halfHylde: "½HYLDE",
    halfCc: "½CC",
    halfPll: "½PLL",
    quaterPll: "1/4 PLL",
    dsHalfCC: "DS ½CC",
    dsHalfHylde: "DS ½HYLDE",
    euroCc: "EURO CC",
    euroHylde: "EURO HYLDE",
    //table lables
    save: "Save all",
    reset: "Reset",
    edit: "Edit",
    resetConfirm:
      "Are you sure you want to unapprove trip? The changes will be saved but the rows will become white.",
    confirmed: "Confirmed",
    actions: "Actions",
    editLines: "Edit lines",
    firstRow: "First Row",
    previousRow: "Previous Row",
    nextRow: "Next Row",
    lastRow: "Last Row",
    searchTable: "Search",
    rows: "Rows",
    updated: "Updated",
    //parking app
    parkingApp: "Parking App",
    parkingLot: "Parking lot",
    vehicleCode: "Vehicle Code",
    noPlate: "No. Plate",
    trailerNoPlate: "Trailer No Plate",
    modelType: "Model Type",
    trailerType: "Trailer type",
    vehicleType: "Vehicle type",
    gsm: "GSM",
    department: "Department",
    subdepartment: "Subdepartment",
    subcontractor: "Subcontractor",
    nameSubcontractor: "Subcontractor name",
    mainDriver: "Main driver",
    secondaryDriver: "Secondary driver",
    userGroup: "User group",
    daylist: "Choose for Daylist",
    group: "User Group",
    addedBy: "Added by",
    createdAt: "Created at",
    openScanApp: "Go to scan App",
    driverActive: "Active",
    morningListTitle: "Morning list",
    brand: "Brand",
    departureDate: "Departure date",
    incoming: "Incoming",
    outgoing: "Outgoing",

    number: "Number",
    fullName: "Full name",
    mobile: "Mobile",
    mail: "Mail",
    trailerCode: "Trailer code",
    yes: "Yes",
    no: "No",
    groupByTrips: "Group by trips",
    showAllMovements: "Show all movements",
    partialTripNr: "Trip number",
    vehicle: "Vehicle",
    startTime: "Start time",
    //errors
    error: "Error",
    errorAddress: "The type of packaging cannot be posted to the customer",
    updateRow: "Approve focused row. (ENTER)",
    updateTrip: "Approve the WHOLE trip. (Ctrl+ENTER)",
    unApprove: "Unapprove trip(make trip white)",
    unApproveRow: "Unapprove row",
    trip: "Trip",
    total: "Total",
    page: "Page",
    //packages
    CC: "Cont.",
    "½CC": "½ Cont.",
    EUPL: "Euro pallet",
    "½RFID CC": "½ Cont. red",
    "½HYLDE": "½Shelve",
    "½PLL": "½ pallet",
    "1/4 PLL": "1/4 Pallet",
    "DS ½CC": "DS ½Cont.",
    "DS ½HYLDE": "DS ½ shelve",
    "EURO CC": "EUR Cont.",
    "EURO HYLDE": "Euro shelve",
    ONEWAY: "Oneway",
    STPL: "Stor pallet",

    "FORL&dot;": "Extensions",
    HYLDER: "Shelves",
    KASSE: "Box",
    PALLE: "Pallet",
    "RFID CC": "CC (Red)",
    STK: "Piece",
    "T5 RFID CC": "Rfid CC (Green)",

    downloadTripReceipt: "Download trip receipt",
    downloadPdf: "Download Freightletter",
    undoRow: "Unapprove",
    reverseTrips: "Reverse trips",
    sortTripsBy: "Sort trips by",
    Gates: "Actual Gate",
    PartialTripNr: "Trip Nr.",
    StartTime: "Start Time",
    movedAfterApproval: "Replanned movements",
    showNotifications: "Show notfications",
    newTrip: "New trip nr",
    seeNotifications: "See notifications",
    shipmentsMovedAlert: `Some movements that you approved was replanned to another trip. Please check the notifications.`,

    oldTrip: "Approved trip nr",
    oldGate: "Approved gate",
    suggestedGate: "Suggested gate"
  },
  da: {
    languageName: "Dansk",
    flagUrl: dkFlag,
    feedbackApp: "Tilbagemelding App",
    login: "log på",
    feedback: "Tilbagemelding",
    terminal: "Terminal liste",
    hardRefresh: "Hård opfriskning",
    search: "Søg tur",
    showHide: "Vis/Skjul felter",
    showHideDeps: "Vis/skjul subdepartments",
    logout: "Logud",
    profile: "Profil",
    username: "Brugernavn",
    password: "Adgangskode",
    //table fields
    addRecord: "Tilføj til Daglisten",
    deleteRecord: "Slet fra morgen listen",
    sendername: "Afsender",
    clientsClient: "Kundes kundenr",
    adreseeName: "Modtager",
    adresseCountry: "Land",
    qty: "Antal",
    loadedQty: "Faktisk antal",
    unit: "Enhed",
    units: "Enheder",
    t5rfidcc: "T5 RFID CC",
    forl: "FORL",
    eupl: "EUPL",
    actualGate: "Portnr.",
    hylder: "Hylder",
    trailer: "Trailer",
    //new fields
    rfidCc: "RFID Cont. (Rød)",
    halfRfidCc: "½ Cont. med lås",
    halfHylde: "½Hylde",
    halfCc: "½ Cont.",
    halfPll: "½ palle",
    quaterPll: "1/4 Palle",
    dsHalfCC: "DS ½Cont.",
    dsHalfHylde: "DS ½ Hylde",
    euroCc: "EUR Cont.",
    euroHylde: "Euro Hylde",
    //table lables
    save: "Bibeholde alle",
    reset: "Afbestille",
    edit: "Redigér",
    actions: "Handlinger",
    editLines: "Edit lines",
    firstRow: "Første række",
    previousRow: "Forrige række",
    nextRow: "Næste række",
    lastRow: "Sidste række",
    searchTable: "Søg",
    rows: "Rækker",
    updated: "Opdateret",
    //parking app
    parkingLot: "Parking plads",
    vehicleCode: "køretøjs kode",
    departureDate: "Afgangsdato",
    incoming: "Indgående",
    outgoing: "Udgående",

    department: "Afdeling",
    resetConfirm: "Er du sikker på, du vil nulstile tabel ændringer?",
    confirmed: "Bekræftet",
    parkingApp: "ParkingApp",
    noPlate: "Nummerplade",
    trailerNoPlate: "Trailer nummerplade",
    modelType: "Modeltype",
    trailerType: "Trailertype",
    vehicleType: "Biltype",
    gsm: "GSM",
    subdepartment: "Underafdeling",
    subcontractor: "Underleverandør",
    nameSubcontractor: "Underleverandør navn",
    mainDriver: "Chauffør",
    secondaryDriver: "Chauffør 2",
    morningListTitle: "Morgenliste",
    brand: "Mærke",
    number: "Nummer",
    fullName: "Navn",
    mobile: "Mobil",
    mail: "E-mail",
    trailerCode: "Trailer kode",

    daylist: "Vælg for daglisten",
    groups: "Bruger grupper",
    addedBy: "Tilføjet af",
    createdAt: "Oprettet",
    addTruck: "Tilføj til morgen listen",
    openScanApp: "Gå¨til Scan App'en",
    driverActive: "Aktiv",
    yes: "Ja",
    no: "Ikke",
    groupByTrips: "Gruppér efter ture",
    showAllMovements: "Vis alle sendinger",
    partialTripNr: "Turnummer",
    vehicle: "Køretøj",
    startTime: "Starttidspunkt",
    error: "Fejl",
    errorAddress: "Emballagetypen kan ikke bogføres på kunde",
    updateRow: "Godkend linje. (ENTER)",
    updateTrip: "Godkend hele turen. (Ctrl + ENTER)",
    unApprove: "Fortryd godkendelse af tur (Gør turen hvid)",
    unApproveRow: "Fortryd godkendelse af linje",
    trip: "Tur",
    total: "Total",
    page: "Side",

    //packages
    CC: "Cont.",
    "½CC": "½ Cont.",
    EUPL: "Euro palle",
    "½RFID CC": "½ Cont. med lås",
    "½HYLDE": "½Hylde",
    "½PLL": "½ palle",
    "1/4 PLL": "1/4 Palle",
    "DS ½CC": "DS ½Cont.",
    "DS ½HYLDE": "DS ½ Hylde",
    "EURO CC": "EUR Cont.",
    "EURO HYLDE": "Euro Hylde",
    ONEWAY: "Oneway",
    STPL: "Stor palle",

    "FORL&dot;": "Forlænger",
    HYLDER: "Hylder",
    KASSE: "Kasse",
    PALLE: "Palle",
    "RFID CC": "CC (Rød)",
    STK: "Stykke",
    "T5 RFID CC": "RFID CC (Grøn)",

    downloadTripReceipt: "Download kvittering for rejsen",
    downloadPdf: "Download Freightletter",
    undoRow: "Afvise",
    reverseTrips: "Omvendte ture",
    sortTripsBy: "Sorter ture efter",

    Gates: "Actual Tur",
    tripNr: "Trip Nr.",
    PartialTripNr: "Trip Nr.",
    StartTime: "Starttidspunkt",
    movedAfterApproval: "Omplanlagte bevægelser",
    showNotifications: "Vis notifikationer",
    newTrip: "Ny tur nr",
    shipmentsMovedAlert:
      "Nogle bevægelser, som du godkendte, blev omplanlagt til en anden tur. Tjek venligst underretningerne.",
    oldTrip: "Godkendt tur nr",
    oldGate: "Godkendt port",
    suggestedGate: "Foreslået port"
  }
};
