import React from "react";
import Icon from "@material-ui/core/Icon";
import { Button, Tooltip } from "@material-ui/core";
import { BlobProvider, PDFViewer } from "@react-pdf/renderer";
import { Loader } from "../Loader";
import { useLang } from "../../context/lang";
import { TripPdf } from "./PdfTemplate";

// Create styles

export const DownloadPdf = ({ trip, size = "small", button = false }) => {
  const [isReady, setIsReady] = React.useState(false);
  const isClicked = React.useRef(false);
  const { t } = useLang();
  return isReady ? (
    <BlobProvider
      document={<TripPdf t={t} trip={trip} />}
      style={{ color: "inherit", textDecoration: "none", height: "24px" }}
    >
      {({ blob, url, loading, error }) => {
        if (url && !isClicked.current) {
          const tempLink = document.createElement("a");
          tempLink.href = url;
          tempLink.setAttribute("download", `trip_${trip.PartialTripNr}.pdf`);
          tempLink.click();
          isClicked.current = true;
          setIsReady(false);
        }
        return (
          <Loader
            marginX={button ? 8 : "auto"}
            margin={button ? 1 : 0}
            loading={loading}
          >
            {button ? (
              <Button color="primary" variant="outlined">
                {t("downloadTripReceipt")}
              </Button>
            ) : (
              <Icon color="primary">format_list_numbered_rtl</Icon>
            )}
          </Loader>
        );
      }}
    </BlobProvider>
  ) : button ? (
    <Button
      onClick={e => {
        setIsReady(true);
      }}
      color="primary"
      variant="outlined"
    >
      {t("downloadTripReceipt")}
    </Button>
  ) : (
    <Tooltip title={t("downloadTripReceipt")}>
      <Icon
        onClick={e => {
          setIsReady(true);
        }}
        color="primary"
        fontSize="large"
      >
        format_list_numbered_rtl
      </Icon>
    </Tooltip>
  );
};

export const PdfView = ({ trip }) => {
  const { t } = useLang();
  return trip ? (
    <PDFViewer width="100%" height="500px">
      <TripPdf trip={trip} t={t} />
    </PDFViewer>
  ) : null;
};
