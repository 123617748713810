import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLang } from "../context/lang";

export const ResetDialog = ({ resetOpen, handleToogleReset, handleReset }) => {
  const { t } = useLang();
  const styles = useStyles();
  const yesButtonRef = React.useRef(null);

  const handleDialogEntered = () => {
    yesButtonRef.current.focus();
  };

  return (
    <Dialog
      open={resetOpen}
      onClose={handleToogleReset}
      onEntered={handleDialogEntered}
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("resetConfirm")}</DialogTitle>
      <DialogActions>
        <Button
          onClick={handleReset}
          ref={yesButtonRef}
          className={styles.dangerButton}
        >
          {t("yes")}
        </Button>
        <Button onClick={handleToogleReset} color="default">
          {t("no")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  dangerButton: {
    color: theme.palette.error.main
  },
  margin: {
    margin: "1rem"
  }
}));
